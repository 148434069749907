import { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { useQuery, useMutation, gql } from '@apollo/client';
import { Alert, Button, TextField } from '@mui/material';

import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import ListItem from 'components/ListItem';
import Title from 'components/Title';

import DefaultLayout from 'layouts/DefaultLayout';

import theme from './theme.module.scss';

type PurseQueryResponse = {
  purse: {
    id: string;
    amount: number;
    issuedBy: {
      id: string;
      name: string;
    };
  };
};

const PURSE = gql`
  query getData {
    purse {
      id
      amount
      issuedBy {
        id
        name
      }
    }
  }
`;

const CONSUME_INVITATION_ID = gql`
  mutation consumeInvitationId($id: String!) {
    consumeInvitationId(id: $id) {
      id
      amount
      issuedBy {
        id
        name
      }
    }
  }
`;

const VerifyInvitationId: FC = () => {
  const [invitationId, setInvitationId] = useState('');

  const [consumeInvitationId, { loading, error }] = useMutation(CONSUME_INVITATION_ID, {
    update(cache, { data: { consumeInvitationId } }) {
      const fetchPurseQuery = cache.readQuery({
        query: PURSE,
      });
      if (!fetchPurseQuery) return;
      cache.writeQuery({
        query: PURSE,
        data: {
          purse: consumeInvitationId,
        },
      });
    },
  });

  return (
    <>
      <p>You can add one now by entering the Cuttle invitation ID given to you by your employer.</p>
      <TextField
        placeholder="Invite Code"
        onChange={(e) => setInvitationId(e.target.value)}
        value={invitationId}
        fullWidth
        size="small"
      />
      {error ? (
        <Alert severity="error" className={theme.error}>
          An error occurred. Please check the Invitation Id or try again later.
        </Alert>
      ) : null}
      <Button
        fullWidth
        variant="contained"
        onClick={() => consumeInvitationId({ variables: { id: invitationId } })}
        disabled={loading}
        className={theme.submitButton}
      >
        Submit
      </Button>
    </>
  );
};

const LinkedAccounts: FC = () => {
  const history = useHistory();

  const { data, loading, error } = useQuery<PurseQueryResponse>(PURSE, {
    fetchPolicy: 'network-only',
  });

  const renderContent = () => {
    if (loading) {
      return <LoadingIndicator />;
    }

    if (error || !data) {
      return <ErrorMessage refresh={() => window.location.reload()} />;
    }

    const purse = data.purse;

    if (!purse) {
      return (
        <>
          <p> You have no connected company accounts.</p>
          <VerifyInvitationId />
        </>
      );
    }

    return (
      <>
        <ListItem label="Company" value={purse.issuedBy.name} />
        <ListItem label="Amount" value={`₹ ${purse.amount}`} />
      </>
    );
  };

  return (
    <DefaultLayout>
      <Title name="Linked Accounts" navigateBack={() => history.goBack()} />
      {renderContent()}
    </DefaultLayout>
  );
};

export default LinkedAccounts;
