import cartImage from 'media/cart.png';
import { Button } from '@mui/material';

import theme from './theme.module.scss';

const EmptyCart = ({ goToVendors }) => {
  return (
    <div className={theme.container}>
      <img src={cartImage} className={theme.image} alt="empty-cart" />
      <p className={theme.header}>Your cart is empty</p>
      <p className={theme.message}>Looks like you haven't added anything to your cart yet</p>
      <Button variant="outlined" onClick={goToVendors}>
        Browse Vendors
      </Button>
    </div>
  );
};

export default EmptyCart;
