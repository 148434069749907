import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Divider } from '@mui/material';

import CartDish from './CartDish';
import CartContext, { CartDishType } from 'contexts/CartContext';

import AddNotesButton from 'components/AddNotesButton';

import theme from './theme.module.scss';
import { isAuthenticated } from 'utils/auth';

type CartDishesProps = {
  dishes: CartDishType[];
  onCheckout: () => void;
  setTotalAmount: (number) => void;
  viewVendor: () => void;
  loading: boolean;
};

const CartDishes: FC<CartDishesProps> = ({
  dishes,
  onCheckout,
  setTotalAmount,
  viewVendor,
  loading,
}) => {
  const history = useHistory();
  const { cartState } = React.useContext(CartContext);
  const [total, setTotal] = useState(0);

  const vendorOnCart = cartState.vendorOnCart;

  useEffect(() => {
    let totalAmount = 0;
    dishes.forEach((dish) => {
      let dishPrice = dish.quantity * dish.price;
      let combinationPrice = 0;
      if (dish.combinations) {
        dish.combinations.forEach((combination) => {
          if (combination.option) {
            combinationPrice += combination.option.price;
          }
          if (combination.addOns) {
            combination.addOns.forEach((addOn) => (combinationPrice += addOn.price));
          }
        });
      }
      totalAmount += dishPrice + combinationPrice;
      setTotal(totalAmount);
      setTotalAmount(totalAmount);
    });
  });

  function makePayment() {
    if (isAuthenticated()) {
      onCheckout();
    } else {
      history.push('/login?redirectTo=cart');
    }
  }

  return (
    <div className={theme.container}>
      <div className={theme.informationContainer}>
        <p className={theme.header}>Order Details</p>
        <div onClick={viewVendor}>
          <p className={theme.vendorName}>{vendorOnCart.name}</p>
        </div>
      </div>
      {dishes.map((dish) => (
        <div className={theme.dishContainer} key={dish.id}>
          <CartDish dish={dish} />
        </div>
      ))}
      <br />
      <AddNotesButton />
      <br />
      <br />
      <Divider />
      <div className={theme.billDetailsContainer}>
        <span className={theme.label}>Total</span>
        <span className={theme.price}>₹ {total}</span>
      </div>
      <Button
        variant="contained"
        className={theme.makePaymentButton}
        size="large"
        fullWidth
        onClick={makePayment}
        disabled={loading}
      >
        MAKE PAYMENT
      </Button>
    </div>
  );
};

export default CartDishes;
