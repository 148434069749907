import { FC, Dispatch, useState, useContext, useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  Dialog,
  TextField,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';

import CartContext from '../../contexts/CartContext';

import { ActionType, OrderNotesActionType } from '../../reducers/cartReducer';

import theme from './theme.module.scss';

const AddNotesModal: FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const { cartState, cartDispatch } = useContext(CartContext);
  const [notes, setNotes] = useState(cartState.notes);

  const orderNotesDispatch: Dispatch<ActionType & OrderNotesActionType> = cartDispatch;

  useEffect(() => {
    setNotes(cartState.notes);
  }, [cartState.notes]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Notes for the Restaurant</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This is a good place to let the restaurant know what you want or in case the restaurant
          delivers, where you're at so they can find you easily.
        </DialogContentText>
        <br />
        <TextField
          className={theme.inputField}
          placeholder="Write here"
          multiline={true}
          autoFocus={true}
          fullWidth
          defaultValue={notes}
          onChange={(e) => {
            setNotes(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            orderNotesDispatch({
              type: 'ORDER_NOTES',
              payload: {
                notes,
              },
            });
            onClose();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AddNotesButton: FC = () => {
  const [showNotesModal, toggleNotesModal] = useState(false);
  const { cartState, cartDispatch } = useContext(CartContext);

  const orderNotesDispatch: Dispatch<ActionType & OrderNotesActionType> = cartDispatch;

  return (
    <>
      {cartState.notes ? (
        <Card sx={{ backgroundColor: '#f5f5f5' }} elevation={0}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Note to the restaurant
            </Typography>
            <Typography variant="body1">{cartState.notes}</Typography>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              onClick={() => {
                orderNotesDispatch({
                  type: 'ORDER_NOTES',
                  payload: {
                    notes: '',
                  },
                });
              }}
            >
              Clear
            </Button>
            <Button size="small" onClick={() => toggleNotesModal(true)}>
              Edit
            </Button>
          </CardActions>
        </Card>
      ) : (
        <Button startIcon={<CreateIcon />} onClick={() => toggleNotesModal(true)}>
          Add a note to the restaurant
        </Button>
      )}
      <AddNotesModal
        open={showNotesModal}
        onClose={() => {
          toggleNotesModal(false);
        }}
      />
    </>
  );
};

export default AddNotesButton;
