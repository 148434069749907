import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { TextField } from '@mui/material';
import Cookies from 'js-cookie';
import { gql, useQuery } from '@apollo/client';
import classNames from 'classnames';

import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import CheckIcon from '@mui/icons-material/Check';

import DefaultLayout from 'layouts/DefaultLayout';

import Title from 'components/Title';
import LocationContext from 'contexts/LocationContext';
import CartContext from 'contexts/CartContext';

import theme from './theme.module.scss';

type AllFoodCourtsQueryResponse = {
  foodCourts: {
    id: string;
    name: string;
  }[];
};

const ALL_FOODCOURTS = gql`
  query getData {
    foodCourts {
      id
      name
    }
  }
`;

const ShowFoodCourts: FC = () => {
  const history = useHistory();
  const { changeLocation } = useContext(LocationContext);
  const { cartDispatch } = React.useContext(CartContext);

  const {
    loading: allFoodCourtsLoading,
    error: allFoodCourtsError,
    data: allFoodCourtsData,
    refetch: allFoodCourtsRefetch,
  } = useQuery<AllFoodCourtsQueryResponse>(ALL_FOODCOURTS, {
    fetchPolicy: 'network-only',
  });

  const [currentFoodCourt, setCurrentFoodCourt] = useState<string | null>('');
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    if (Cookies.get('currentFoodCourt')) {
      setCurrentFoodCourt(Cookies.get('currentFoodCourt'));
    }
  }, []);

  const writeFoodCourtToStorage = (value) => {
    try {
      Cookies.set('currentFoodCourt', value, { expires: 365 });
    } catch (e) {
      console.log('seterror', e);
    }
  };

  const renderContent = () => {
    if (allFoodCourtsLoading) return <LoadingIndicator />;

    if (allFoodCourtsError || !allFoodCourtsData)
      return <ErrorMessage refresh={allFoodCourtsRefetch} />;

    const allFoodCourts = allFoodCourtsData.foodCourts;

    if (allFoodCourts.length === 0) {
      return (
        <div className={theme.emptyVendors}>
          <p className={theme.emptyText}>
            There are no open food courts at this time. Kindly check back later.
          </p>
        </div>
      );
    }

    return (
      <div className={theme.emptyVendors}>
        <p className={theme.emptyText}>
          Search below to find the food court you're looking to order from
        </p>
        <TextField
          className={theme.formInput}
          placeholder={'Search'}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          fullWidth
        />
        <div className={theme.foodCourtsContainer}>
          {allFoodCourts
            .filter((fc) =>
              searchTerm.length < 1
                ? true
                : fc.name.toLowerCase().includes(searchTerm.toLowerCase()) && searchTerm.length > 1
            )
            .sort((a, b) => {
              switch (true) {
                case a.id === currentFoodCourt:
                  return -1;
                case b.id === currentFoodCourt:
                  return 1;
                default:
                  return 0;
              }
            })
            .map((foodCourt) => (
              <div
                key={foodCourt.id}
                className={classNames(
                  theme.foodCourt,
                  foodCourt.id === currentFoodCourt ? theme.selected : null
                )}
                onClick={() => {
                  writeFoodCourtToStorage(foodCourt.id);
                  changeLocation({ currentFoodCourt: foodCourt.id });
                  cartDispatch({
                    type: 'CLEAR_CART',
                    payload: null,
                  });
                  history.push('Home', {
                    foodCourtId: foodCourt.id,
                  });
                }}
              >
                <div>
                  <p className={theme.locationName}>{foodCourt.name}</p>
                  <p className={theme.locationAddress}>{'Food Court'}</p>
                </div>

                {foodCourt.id === currentFoodCourt && <CheckIcon color="success" />}
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <div>{renderContent()}</div>
    </>
  );
};

const ChangeLocation: FC = () => {
  const history = useHistory();

  return (
    <>
      <DefaultLayout>
        <Title name="Change Location" navigateBack={() => history.goBack()} />
        <ShowFoodCourts />
      </DefaultLayout>
    </>
  );
};

export default ChangeLocation;
