import { FC } from 'react';
import { Divider } from '@mui/material';

import theme from './theme.module.scss';

type VendorInformationProps = {
  name: string;
  description?: string;
};

const VendorInformation: FC<VendorInformationProps> = ({ name, description }) => {
  return (
    <div className={theme.vendorInformation}>
      <p className={theme.name}>{name}</p>
      <Divider />
      {description && description !== '' ? (
        <p className={theme.description}>{description}</p>
      ) : null}
    </div>
  );
};

export default VendorInformation;
