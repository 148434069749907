import { FC, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Alert, InputAdornment, TextField } from '@mui/material';

import LoadingButton from 'components/LoadingButton';

import theme from './theme.module.scss';

type GenerateOTPMutationResponse = {
  generateOTP: {
    phoneNumber: string;
  };
};

type GenerateOTPMutationVariables = {
  phoneNumber: string;
};

const GENERATE_OTP = gql`
  mutation generateOTP($phoneNumber: String!) {
    generateOTP(phoneNumber: $phoneNumber) {
      phoneNumber
    }
  }
`;

const EnterPhoneNumber: FC<{
  onExistingUser: (phoneNumber: string) => void;
  onNewUser: (phoneNumber: string) => void;
}> = ({ onExistingUser, onNewUser }) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [validationError, setValidationError] = useState('');
  const [error, setError] = useState(false);
  const [isNewUser, setNewUser] = useState(false);

  const [generateOtp, { loading, data }] = useMutation<
    GenerateOTPMutationResponse,
    GenerateOTPMutationVariables
  >(GENERATE_OTP);

  function onSubmit() {
    setValidationError('');
    setError(false);

    const numbers = /^\d{10}$/;
    if (!new RegExp(numbers).test(phoneNumber)) {
      setValidationError('Please enter a valid phone number. 10 digits long.');
      return;
    }

    generateOtp({
      variables: {
        phoneNumber,
      },
    })
      .then(() => onExistingUser(phoneNumber))
      .catch((e) => {
        if (e.graphQLErrors) {
          for (const { extensions } of e.graphQLErrors) {
            if (extensions && extensions.code === 'UserNotFoundException') {
              onNewUser(phoneNumber);
              setNewUser(true);
              return;
            }
          }
        }
        setError(true);
      });
  }

  return (
    <div className={theme.container}>
      <TextField
        label="Phone Number"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        fullWidth
        type="number"
        InputProps={{
          startAdornment: <InputAdornment position="start">+91</InputAdornment>,
        }}
        error={!!validationError}
        helperText={validationError}
        disabled={loading || !!(data && data.generateOTP) || isNewUser}
      />
      {error ? (
        <Alert severity="error" className={theme.errorAlert}>
          There was an error processing your request. Kindly try again later
        </Alert>
      ) : null}
      {(data && data.generateOTP) || isNewUser ? null : (
        <>
          <p className={theme.disclaimer}>
            By entering your phone number, I agree to the Terms of Service, Privacy Policy and
            Cancellation Policy.
          </p>
          <LoadingButton
            variant="contained"
            className={theme.submitButton}
            onClick={onSubmit}
            loading={loading}
          >
            Login
          </LoadingButton>
        </>
      )}
    </div>
  );
};

export default EnterPhoneNumber;
