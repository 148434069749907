import { useContext } from 'react';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import CartContext from 'contexts/CartContext';

import DefaultLayout from 'layouts/DefaultLayout';

import { isAuthenticated } from 'utils/auth';

import theme from './theme.module.scss';

const CartButton = ({ onClick }) => {
  const { cartState } = useContext(CartContext);

  let totalQuantity = 0;
  cartState.dishesOnCart.forEach((dish) => {
    totalQuantity += dish.quantity;
  });

  if (totalQuantity === 0) {
    return null;
  }

  return (
    <div className={theme.cartButtonContainer} style={{ bottom: isAuthenticated() ? '56px' : 0 }}>
      <DefaultLayout>
        <div className={theme.cartButton} onClick={onClick}>
          <div>
            <span className={theme.count}>
              {totalQuantity} {totalQuantity === 1 ? 'item' : 'items'}
            </span>
          </div>
          <div className={theme.viewCartContainer}>
            <span className={theme.text}>VIEW CART</span>
            <ShoppingCartIcon />
          </div>
        </div>
      </DefaultLayout>
    </div>
  );
};

export default CartButton;
