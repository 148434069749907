import { FC } from 'react';

import AddDishButton from 'components/AddDishButton';
import FoodIcon from 'components/FoodIcon';
import { DishType } from 'components/AddDishButton';
import { Divider } from '@mui/material';

import theme from './theme.module.scss';

type DishCardProps = {
  dish: DishType;
  isLastDish: boolean;
  vendor: {
    id: string;
    name: string;
  };
};

const DishCard: FC<DishCardProps> = ({ dish, isLastDish, vendor }) => {
  const addOns = dish.addOns;
  const options = dish.options;

  return (
    <>
      <div className={theme.dishCard}>
        <FoodIcon isVeg={dish.isVegetarian} />
        <div className={theme.informationContainer}>
          <div className={theme.left}>
            <p className={theme.name}>{dish.name}</p>
            <p className={theme.description}>{dish.description}</p>
            <p className={theme.price}>₹ {dish.price}</p>
          </div>
          <div className={theme.right}>
            {dish.image ? <img className={theme.image} alt="dish" src={dish.image} /> : null}
            <AddDishButton dish={dish} addOns={addOns} options={options} vendor={vendor} />
          </div>
        </div>
      </div>
      {isLastDish ? null : <Divider />}
    </>
  );
};

export default DishCard;
