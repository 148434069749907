import { FC } from 'react';

import theme from './theme.module.scss';

const ListButton: FC<{ label: string; onClick: () => void; icon?: any }> = ({
  label,
  icon,
  onClick,
}) => (
  <div className={theme.listButtonContainer} onClick={onClick}>
    <span className={theme.label}>{label}</span>
    {icon}
  </div>
);

export default ListButton;
