import { FC } from 'react';
import { useHistory } from 'react-router';
import { useQuery, gql } from '@apollo/client';

import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import ListItem from 'components/ListItem';
import Title from 'components/Title';

import DefaultLayout from 'layouts/DefaultLayout';

type UserQueryResponse = {
  me: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
};

const USER = gql`
  query user {
    me {
      id
      firstName
      lastName
      email
      phoneNumber
    }
  }
`;

const PersonalInformation: FC = () => {
  const history = useHistory();
  const { data, loading, error } = useQuery<UserQueryResponse>(USER, {
    fetchPolicy: 'network-only',
  });

  const renderContent = () => {
    if (loading) {
      return <LoadingIndicator />;
    }

    if (error || !data) {
      return <ErrorMessage refresh={() => window.location.reload()} />;
    }

    const user = data.me;

    return (
      <>
        <ListItem label="First Name" value={user.firstName} />
        <ListItem label="Last Name" value={user.lastName} />
        <ListItem label="Email" value={user.email} />
        <ListItem label="Phone Number" value={user.phoneNumber} />
      </>
    );
  };

  return (
    <DefaultLayout>
      <Title name="Personal Information" navigateBack={() => history.goBack()} />
      {renderContent()}
    </DefaultLayout>
  );
};

export default PersonalInformation;
