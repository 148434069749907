import { FC } from 'react';
import { CircularProgress, Button } from '@mui/material';

const LoadingButton: FC<{
  className: string;
  onClick?: () => any;
  loading?: boolean;
  variant?: 'contained' | 'outlined' | 'text';
  fullWidth?: boolean;
}> = ({ className, onClick, loading = false, children, variant, fullWidth = false }) => {
  return (
    <div className={className} style={{ position: 'relative', width: '100%', textAlign: 'center' }}>
      <Button variant={variant} disabled={loading} onClick={onClick} fullWidth={fullWidth}>
        {children}
      </Button>
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </div>
  );
};

export default LoadingButton;
