import { FC } from 'react';
import { Button, Dialog, DialogActions } from '@mui/material';

import errorImage from 'media/error.png';

import theme from './theme.module.scss';

const getErrorContent = (
  code: string | null | undefined
): { title: string; description: string } => {
  switch (code) {
    case 'UNKNOWN':
      return {
        title: 'Something went wrong',
        description: 'Please try again later',
      };
    default:
      return {
        title: 'Something went wrong',
        description: 'Please try again later',
      };
  }
};

const ErrorModal: FC<{
  code: string | null | undefined;
  onClose: () => void;
}> = ({ code, onClose }) => {
  const { title, description } = getErrorContent(code);
  return (
    <Dialog open={code !== null && code !== undefined && code !== ''} onClose={onClose}>
      <div className={theme.errorContainer}>
        <img className={theme.image} src={errorImage} alt="error" />
        <p className={theme.title}>{title}</p>
        <p className={theme.message}>{description}</p>
      </div>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorModal;
