import { FC } from 'react';
import Button from '@mui/material/Button';

import theme from './theme.module.scss';

type ErrorMessageProps = {
  message?: string;
  refresh?: () => void;
};

const ErrorMessage: FC<ErrorMessageProps> = ({ refresh, message }) => {
  const retry = () => {
    if (refresh) {
      refresh();
    }
  };

  return (
    <div className={theme.errorContainer}>
      <p>{message ? message : 'Something went wrong!'}</p>
      {refresh ? (
        <Button variant="outlined" title="Refresh" onClick={retry}>
          Retry
        </Button>
      ) : null}
    </div>
  );
};

export default ErrorMessage;
