import { FC } from 'react';
import classNames from 'classnames';

import theme from './theme.module.scss';

const statusLabel = (status: string) => {
  switch (status) {
    case 'UPCOMING':
      return 'COMING SOON';
    default:
      return status;
  }
};

type VendorProps = {
  vendor: any;
  onSelect: any;
};

const VendorCard: FC<VendorProps> = ({ vendor, onSelect }) => {
  if (vendor.status !== 'OPEN') {
    return (
      <div className={classNames(theme.container, theme.disabled)}>
        <div className={theme.imageContainer}>
          <img className={theme.image} src={vendor.thumbnail} alt="vendor-thumbnail" />
          <div className={theme.dimmer}>
            <p className={theme.status}>{statusLabel(vendor.status)}</p>
          </div>
        </div>
        <div className={theme.labelContainer}>
          <p className={theme.name}>{vendor.name}</p>
          <p className={theme.description}>{vendor.shortDescription}</p>
        </div>
      </div>
    );
  }

  return (
    <div className={theme.container} onClick={onSelect}>
      <div className={theme.imageContainer}>
        <img className={theme.image} src={vendor.thumbnail} alt="vendor-thumbnail" />
      </div>
      <div className={theme.labelContainer}>
        <p className={theme.name}>{vendor.name}</p>
        <p className={theme.description}>{vendor.shortDescription}</p>
      </div>
    </div>
  );
};

export default VendorCard;
