import { createContext } from 'react';

interface LocationContextData {
  currentFoodCourt: string;
  changeLocation: ({ currentFoodCourt }: { currentFoodCourt: string }) => void;
}

const LocationContext = createContext({} as LocationContextData);

export default LocationContext;
