import React from 'react';
import ReactDOM from 'react-dom';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import App from './routes';
import reportWebVitals from './reportWebVitals';

import { getToken, logout } from 'utils/auth';

import './index.scss';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_CUTTLE_BACKEND,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: getToken(),
    },
    onError: ({ graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ extensions }) => {
          if (extensions && extensions.code === 'UNAUTHENTICATED') logout();
        });
      }
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#ff5252',
    },
  },
  typography: {
    fontFamily: 'Montserrat, Arial',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
