import { FC } from 'react';

import AddDishButton from 'components/AddDishButton';
import FoodIcon from 'components/FoodIcon';
import { CartDishType } from 'contexts/CartContext';

import theme from './theme.module.scss';

type CartDishProps = {
  dish: CartDishType;
};

const CartDish: FC<CartDishProps> = ({ dish }) => {
  const dishPrice = () => {
    let combinationPrice = 0;
    if (dish.combinations) {
      dish.combinations.forEach((combination) => {
        if (combination.option) {
          combinationPrice += combination.option.price;
        }
        if (combination.addOns) {
          combination.addOns.forEach((addOn) => (combinationPrice += addOn.price));
        }
      });
    }
    return dish.quantity * dish.price + combinationPrice;
  };

  function showChosenCombination(combinations) {
    if (!combinations) return [];
    return combinations.reduce((acc, curr) => {
      const option = curr.option.name;
      const addOns = curr.addOns ? curr.addOns.map((a) => a.name) : [];
      const combo = [option, ...addOns];
      return [...acc, `${combo.join(', ')}`];
    }, []);
  }

  return (
    <div className={theme.container}>
      <div className={theme.cartDishCard}>
        <FoodIcon isVeg={dish.isVegetarian} />
        <div className={theme.informationContainer}>
          <div className={theme.left}>
            <p className={theme.name}>{dish.name}</p>
            {showChosenCombination(dish.combinations).map((combination, i) => (
              <p key={`chosen-combination-${i}`} className={theme.combination}>
                {combination}
              </p>
            ))}
          </div>
          <div className={theme.right}>
            <AddDishButton dish={dish} addOns={dish.addOns} options={dish.options} />
            <p className={theme.price}>₹ {dishPrice()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartDish;
