import { FC } from 'react';
import { useHistory } from 'react-router';

import Title from 'components/Title';
import DefaultLayout from 'layouts/DefaultLayout';

import theme from './theme.module.scss';

const Help: FC = () => {
  const history = useHistory();
  return (
    <DefaultLayout>
      <Title name="Help" navigateBack={() => history.goBack()} />
      <div>
        <p>
          If you have any issues using this app or with your order, kindly send us an email and
          someone from our team will get in touch with you right away.
        </p>
        <a href="mailto:support@illude.co" rel="noreferrer" target="_blank" className={theme.email}>
          support@illude.co
        </a>
      </div>
    </DefaultLayout>
  );
};

export default Help;
