import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Title from 'components/Title';
import DefaultLayout from 'layouts/DefaultLayout';

import EnterPhoneNumber from './EnterPhoneNumber';
import EnterOTPExisting from './EnterOTPExisting';
import EnterOTPFirstTime from './EnterOTPFirstTime';
import Register from './Register';

import { isAuthenticated, storeLoginCredentials } from 'utils/auth';

function useURLQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const Login: FC = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showOtpInput, toggleOtpInput] = useState<'FIRST_TIME' | 'EXISTING' | 'NONE'>('NONE');
  const [showRegister, toggleRegister] = useState(false);
  const history = useHistory();
  const queryParams = useURLQueryParams();

  useEffect(() => {
    if (isAuthenticated()) history.push('/');
  }, []);

  function onLogin(user, tokenId) {
    storeLoginCredentials(user, tokenId);
    const redirectTo = queryParams.get('redirectTo') ? `/${queryParams.get('redirectTo')}` : '/';
    history.push(redirectTo);
  }

  return (
    <DefaultLayout>
      <Title navigateBack={() => history.goBack()} showDivider={false} />
      <EnterPhoneNumber
        onExistingUser={(phoneNumber) => {
          setPhoneNumber(phoneNumber);
          toggleOtpInput('EXISTING');
        }}
        onNewUser={(phoneNumber) => {
          setPhoneNumber(phoneNumber);
          toggleRegister(true);
        }}
      />
      {showRegister ? (
        <Register
          phoneNumber={phoneNumber}
          onSuccess={() => {
            toggleOtpInput('FIRST_TIME');
          }}
        />
      ) : null}
      {showOtpInput === 'EXISTING' && (
        <EnterOTPExisting
          phoneNumber={phoneNumber}
          onSuccess={(tokenId, user) => onLogin(user, tokenId)}
        />
      )}
      {showOtpInput === 'FIRST_TIME' && (
        <EnterOTPFirstTime
          phoneNumber={phoneNumber}
          onSuccess={(tokenId, user) => onLogin(user, tokenId)}
        />
      )}
    </DefaultLayout>
  );
};

export default Login;
