import { FC, useState, useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import { useQuery, gql } from '@apollo/client';
import Cookies from 'js-cookie';
import classNames from 'classnames';

import DefaultLayout from 'layouts/DefaultLayout';

import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import Title from 'components/Title';
import CartButton from 'components/CartButton';
import { DishType } from 'components/AddDishButton';

import VendorInformation from './VendorInformation';
import DishCard from './DishCard';

import theme from './theme.module.scss';

type DishesByCategory = {
  dishes: DishType[];
  category: {
    id: string;
    name: string;
  };
};

type MenuQueryProps = {
  vendor: {
    id: string;
    name: string;
    description?: string;
    foodCourt: {
      id: string;
    };
    status: string;
    menus: {
      id: string;
      name: string;
      dishesByCategory: DishesByCategory[];
    }[];
  };
};

type MenuQueryVariables = {
  vendorId: string;
};

const MENUS = gql`
  query getData($vendorId: ID!) {
    vendor(id: $vendorId) {
      id
      name
      description
      foodCourt {
        id
      }
      status
      menus {
        id
        name
        dishesByCategory {
          dishes {
            id
            name
            description
            price
            image
            isVegetarian
            options {
              id
              name
              price
              isVegetarian
            }
            addOns {
              id
              name
              price
              isVegetarian
            }
            category {
              id
              name
            }
          }
          category {
            id
            name
          }
        }
      }
    }
  }
`;

function useURLQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const Vendor: FC = () => {
  const history = useHistory();
  const queryParams = useURLQueryParams();
  const { vendorId } = useParams<{ vendorId: string }>();
  const [menuNumber, setMenuNumber] = useState(0);

  const { loading, error, data, refetch } = useQuery<MenuQueryProps, MenuQueryVariables>(MENUS, {
    variables: {
      vendorId,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (queryParams.get('spotId')) {
      Cookies.set('spotId', queryParams.get('spotId'), { expires: 0.014 });
    }
  }, []);

  if (loading) return <LoadingIndicator />;

  if (error || !data)
    return (
      <>
        <div>
          <div className={theme.scrollView}>
            <div className={theme.informationContainer}>
              <Title
                navigateBack={() => (!loading ? history.push('Home') : null)}
                showDivider={false}
              />
            </div>
            <div className={theme.container}>
              <ErrorMessage refresh={refetch} />
            </div>
          </div>
        </div>
      </>
    );

  const vendor = data.vendor;
  const menus = vendor.menus;

  const writeFoodCourtToStorage = (value) => {
    try {
      Cookies.set('currentFoodCourt', value, { expires: 365 });
    } catch (e) {
      console.log('seterror', e);
    }
  };

  writeFoodCourtToStorage(vendor.foodCourt.id);

  if (vendor.status !== 'OPEN')
    return (
      <>
        <DefaultLayout>
          <div className={theme.vendor}>
            <div className={theme.informationContainer}>
              <Title
                navigateBack={() => (!loading ? history.push('/home') : null)}
                showDivider={false}
              />
              <VendorInformation name={vendor.name} description={vendor.description} />

              <div className={theme.closedStatus}>
                <p>This restaurant is currently closed. Please check back later.</p>
              </div>
            </div>
          </div>
        </DefaultLayout>
      </>
    );

  const CategorySection: FC<{ dishesByCategory: DishesByCategory }> = ({ dishesByCategory }) => {
    const dishes = dishesByCategory.dishes;
    const category = dishesByCategory.category;

    return (
      <div key={category.id} className={theme.category}>
        <p className={theme.name}>{category.name}</p>
        {dishes.map((dish, i) => (
          <DishCard
            key={dish.id}
            dish={dish}
            isLastDish={dishes.length - 1 === i}
            vendor={vendor}
          />
        ))}
      </div>
    );
  };

  const MenuSlider: FC = () => {
    return (
      <div className={theme.menuSlider}>
        {menus.map((menu, i) => (
          <div
            key={menu.id}
            className={classNames(theme.menuButton, menuNumber === i ? theme.selected : null)}
            onClick={() => setMenuNumber(i)}
          >
            <span>{menu.name}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <DefaultLayout>
      <div className={theme.vendor}>
        <div className={theme.informationContainer}>
          <Title
            navigateBack={() => (!loading ? history.push('/home') : null)}
            showDivider={false}
          />
          <VendorInformation name={vendor.name} description={vendor.description} />
          <MenuSlider />
        </div>
        {menus[menuNumber].dishesByCategory.map((category, i) => (
          <CategorySection key={i} dishesByCategory={category} />
        ))}
        <div className={theme.bottomPadding} />
      </div>
      <CartButton onClick={() => history.push('/cart')} />
    </DefaultLayout>
  );
};

export default Vendor;
