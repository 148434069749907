import { FC } from 'react';

import theme from './theme.module.scss';

const ListItem: FC<{ label: string; value: string }> = ({ label, value }) => (
  <div className={theme.listItemContainer}>
    <p className={theme.label}>{label}</p>
    <p className={theme.value}>{value}</p>
  </div>
);

export default ListItem;
