import { FC, useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { Alert, TextField } from '@mui/material';

import LoadingButton from 'components/LoadingButton';

import theme from './theme.module.scss';

type RegisterMutationResponse = {
  register: {
    phoneNumber: string;
  };
};

type RegisterMutationVariables = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
};

const REGISTER = gql`
  mutation register(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String!
    $gender: GenderEnumType
  ) {
    register(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      gender: $gender
    ) {
      phoneNumber
    }
  }
`;

const Register: FC<{ phoneNumber: string; onSuccess: () => void }> = ({
  phoneNumber,
  onSuccess,
}) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [validationError, setValidationError] = useState('');
  const [register, { loading, error, data }] = useMutation<
    RegisterMutationResponse,
    RegisterMutationVariables
  >(REGISTER);

  function onSubmit() {
    setValidationError('');

    const emailValidator = /\S+@\S+\.\S+/;

    switch (true) {
      case firstName === '':
        setValidationError('First name is required');
        return true;
      case lastName === '':
        setValidationError('Last name is required');
        return true;
      case email === '':
        setValidationError('Email is required');
        return true;
      case !emailValidator.test(email):
        setValidationError('Please enter a valid email. Example: pepe.silvia@mailroom.com');
        return true;
    }

    register({
      variables: {
        firstName,
        lastName,
        email,
        phoneNumber,
      },
    }).then(() => onSuccess());
  }

  return (
    <div className={theme.container}>
      <p className={theme.subTitle}>Create an account</p>
      <p className={theme.description}>
        Welcome to Cuttle. Provide us with a little information and you can get started right away.
      </p>
      <TextField
        className={theme.input}
        label="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        fullWidth
        disabled={loading || !!(data && data.register)}
      />
      <TextField
        className={theme.input}
        label="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        fullWidth
        disabled={loading || !!(data && data.register)}
      />
      <TextField
        className={theme.input}
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        type="email"
        disabled={loading || !!(data && data.register)}
      />
      {validationError ? (
        <Alert severity="error" className={theme.errorAlert}>
          {validationError}
        </Alert>
      ) : null}
      {error ? (
        <Alert severity="error" className={theme.errorAlert}>
          There was an error processing your request. Kindly try again later
        </Alert>
      ) : null}
      {!(data && data.register) ? (
        <LoadingButton
          variant="contained"
          className={theme.submitButton}
          onClick={onSubmit}
          loading={loading}
        >
          Register
        </LoadingButton>
      ) : null}
    </div>
  );
};

export default Register;
