import { FC, Dispatch, useState, useEffect, useContext } from 'react';
import {
  Button,
  Checkbox,
  IconButton,
  Dialog,
  Drawer,
  Grid,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import CartContext, { CartDishType, CombinationType } from 'contexts/CartContext';

import {
  ActionType,
  VendorActionType,
  AddDishActionType,
  RemoveDishActionType,
} from 'reducers/cartReducer';

import theme from './theme.module.scss';

type ExtraType = {
  id: string;
  name: string;
  price: number;
  isVegetarian: boolean;
};

export interface DishType {
  id: string;
  name: string;
  description: string;
  image: string;
  price: number;
  isVegetarian: boolean;
  category: {
    id: string;
    name: string;
  };
  addOns: ExtraType[];
  options: ExtraType[];
  notes: string;
  scheduledTime: string;
  quantity: number;
}

type AddDishProps = {
  dish: DishType | CartDishType;
  addOns?: ExtraType[];
  options?: ExtraType[];
  vendor?: {
    id: string;
    name: string;
  };
};

const CreateCombinationModal: FC<{
  open: boolean;
  onSubmit: (combination: CombinationType) => void;
  onClose: () => void;
  addOns?: ExtraType[];
  options?: ExtraType[];
}> = ({ open, onSubmit, onClose, addOns = [], options = [] }) => {
  const [selectedAddOns, setSelectedAddOns] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<any>('');

  function setDefaultOption() {
    if (options && options.length) {
      setSelectedOption(options[0].id);
    }
  }

  useEffect(() => {
    setDefaultOption();
  }, []);

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Grid container alignItems="center" justifyContent="center" direction="column">
        <Grid container xl={3} lg={5} md={8} sm={12} xs={12} direction="column">
          <div className={theme.drawer}>
            <p className={theme.header}>Options</p>
            <RadioGroup
              name="choose-options"
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              {options.map((option) => (
                <FormControlLabel
                  key={option.id}
                  value={option.id}
                  control={<Radio />}
                  label={`${option.name} (+ ₹${option.price})`}
                />
              ))}
            </RadioGroup>
            <p className={theme.header}>Add Ons</p>
            <FormGroup>
              {addOns.map((addOn) => (
                <FormControlLabel
                  key={addOn.id}
                  control={
                    <Checkbox
                      key={addOn.id}
                      checked={selectedAddOns.includes(addOn)}
                      onClick={() => {
                        if (selectedAddOns.includes(addOn)) {
                          setSelectedAddOns(selectedAddOns.filter((a) => a !== addOn));
                        } else {
                          setSelectedAddOns([...selectedAddOns, addOn]);
                        }
                      }}
                    />
                  }
                  label={`${addOn.name} (+ ₹ ${addOn.price})`}
                />
              ))}
            </FormGroup>
            <div className={theme.footer}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  onSubmit({
                    //@ts-ignore
                    option: options.find((o) => o.id === selectedOption),
                    addOns: selectedAddOns,
                  });
                  setSelectedAddOns([]);
                  setDefaultOption();
                }}
                className={theme.button}
              >
                Add to Cart
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Drawer>
  );
};

const RemoveCombinationModal: FC<{
  currentCombinations: CombinationType[];
  open: boolean;
  onSubmit: (modifiedCombinations: CombinationType[]) => void;
  onClose: () => void;
}> = ({ open, currentCombinations, onSubmit, onClose }) => {
  const [selectedOption, setSelectedOption] = useState<number>(0);

  const getTitle = (combination: CombinationType) => {
    const addOns =
      combination.addOns && combination.addOns.length
        ? `- (${combination.addOns.map((a) => a.name).join(', ')})`
        : '';
    return `${combination.option.name} ${addOns}`;
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <Grid container alignItems="center" justifyContent="center" direction="column">
        <Grid container xl={3} lg={5} md={8} sm={12} xs={12} direction="column">
          <div className={theme.drawer}>
            <p className={theme.header}>Chosen combinations</p>
            <FormGroup>
              {currentCombinations.map((combination, i) => (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      key={`current-combination-${i}`}
                      checked={selectedOption === i}
                      onClick={() => setSelectedOption(i)}
                    />
                  }
                  label={getTitle(combination)}
                />
              ))}
            </FormGroup>
            <Button
              className={theme.button}
              fullWidth
              variant="contained"
              onClick={() => onSubmit(currentCombinations.filter((_c, i) => i !== selectedOption))}
            >
              Remove from Cart
            </Button>
          </div>
        </Grid>
      </Grid>
    </Drawer>
  );
};

const AddDishButton: FC<AddDishProps> = ({ dish, addOns, options, vendor }) => {
  const { cartState, cartDispatch } = useContext(CartContext);
  const [cartErrorComponent, setCartErrorComponent] = useState<FC | null>(null);

  const vendorDispatch: Dispatch<ActionType & VendorActionType> = cartDispatch;
  const addDishDispatch: Dispatch<ActionType & AddDishActionType> = cartDispatch;
  const removeDishDispatch: Dispatch<ActionType & RemoveDishActionType> = cartDispatch;

  const dishFound = cartState.dishesOnCart.find((d) => d.id === dish.id);

  let currentCount = 0;
  let currentCombinations: CombinationType[] = [];

  if (dishFound) {
    currentCount = dishFound.quantity;
    currentCombinations = dishFound.combinations ? [...dishFound.combinations] : [];
  }

  const [showCreateCombinationModal, toggleCreateCombinationModal] = useState(false);
  const [showRemoveCombinationModal, toggleRemoveCombinationModal] = useState(false);

  const incrementDishCount = () => {
    if ((addOns && addOns.length) || (options && options.length)) {
      toggleCreateCombinationModal(true);
      return;
    }
    checkVendorAndAddDish(dish);
  };

  const decrementDishCount = () => {
    if (currentCombinations && currentCombinations.length) {
      toggleRemoveCombinationModal(true);
      return;
    }
    removeDish(dish);
  };

  const checkVendorAndAddDish = (dish: DishType | CartDishType, combination?: CombinationType) => {
    let totalQuantity = 0;
    cartState.dishesOnCart.forEach((dish) => {
      totalQuantity += dish.quantity;
    });
    if (totalQuantity > 14) {
      const errorDialog: FC = () => {
        return (
          <Dialog open={true} onClose={() => setCartErrorComponent(null)}>
            <DialogTitle>Cart Limit Reached</DialogTitle>
            <DialogContent>
              <DialogContentText>
                This restaurant has a limit for how many items can be ordered at once.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={() => setCartErrorComponent(null)}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        );
      };
      setCartErrorComponent(errorDialog);
      return;
    }

    if (cartState.vendorOnCart.id && cartState.dishesOnCart.length) {
      if (vendor && vendor.id !== cartState.vendorOnCart.id) {
        const errorDialog: FC = () => {
          return (
            <Dialog open={true} onClose={() => setCartErrorComponent(null)}>
              <DialogTitle>Replace cart dish?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {`Your cart contains dishes from ${cartState.vendorOnCart.name}. Do you want to remove those dishes and order from here instead?`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={() => setCartErrorComponent(null)}>
                  No
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    vendorDispatch({
                      type: 'VENDOR',
                      payload: {
                        id: vendor.id,
                        name: vendor.name,
                      },
                    });
                    addDish(dish, combination);
                    setCartErrorComponent(null);
                  }}
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          );
        };
        setCartErrorComponent(errorDialog);
      } else {
        addDish(dish, combination);
      }
    } else {
      if (vendor) {
        vendorDispatch({
          type: 'VENDOR',
          payload: {
            id: vendor.id,
            name: vendor.name,
          },
        });
      }
      addDish(dish, combination);
    }
  };

  const addDish = (dish: DishType | CartDishType, combination?: CombinationType) => {
    addDishDispatch({
      type: 'ADD_DISH',
      payload: {
        dish,
        combination,
      },
    });
  };

  const removeDish = (dish: DishType | CartDishType, modifiedCombinations?: CombinationType[]) => {
    removeDishDispatch({
      type: 'REMOVE_DISH',
      payload: {
        dish,
        modifiedCombinations,
      },
    });
  };

  return (
    <div>
      {currentCount === 0 ? (
        <IconButton onClick={incrementDishCount} size="small" className={theme.button}>
          <AddIcon />
        </IconButton>
      ) : (
        <div className={theme.buttonContainer}>
          <IconButton onClick={decrementDishCount} size="small" className={theme.button}>
            <RemoveIcon />
          </IconButton>
          <p className={theme.count}>{currentCount}</p>
          <IconButton onClick={incrementDishCount} size="small" className={theme.button}>
            <AddIcon />
          </IconButton>
        </div>
      )}
      <CreateCombinationModal
        open={showCreateCombinationModal}
        addOns={addOns}
        options={options}
        onSubmit={(combination) => {
          checkVendorAndAddDish(dish, combination);
          toggleCreateCombinationModal(false);
        }}
        onClose={() => {
          toggleCreateCombinationModal(false);
        }}
      />
      <RemoveCombinationModal
        open={showRemoveCombinationModal}
        currentCombinations={currentCombinations}
        onSubmit={(modifiedCombinations) => {
          removeDish(dish, modifiedCombinations);
          toggleRemoveCombinationModal(false);
        }}
        onClose={() => {
          toggleRemoveCombinationModal(false);
        }}
      />
      {cartErrorComponent}
    </div>
  );
};

export default AddDishButton;
