import { useLocation, useHistory } from 'react-router-dom';
import { Paper, BottomNavigation, BottomNavigationAction } from '@mui/material';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

const BottomBar = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={2}>
      <BottomNavigation
        showLabels
        value={location.pathname}
        onChange={(event, newValue) => {
          history.push(newValue);
        }}
      >
        <BottomNavigationAction
          label="Explore"
          value="/home"
          icon={<RestaurantMenuOutlinedIcon fontSize="small" />}
        />
        <BottomNavigationAction
          label="Orders"
          value="/orders"
          icon={<AssignmentOutlinedIcon fontSize="small" />}
        />
        <BottomNavigationAction
          label="Profile"
          value="/profile"
          icon={<PersonOutlineOutlinedIcon fontSize="small" />}
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomBar;
