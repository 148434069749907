import { FC, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, Radio } from '@mui/material';
import classNames from 'classnames';

import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';

import theme from './theme.module.scss';

type PaymentModesQueryResponse = {
  vendor: {
    id: string;
    paymentModes: string[];
  };
  purse: {
    id: string;
    amount: number;
    allowPartialPayments: boolean;
    issuedBy: {
      name: string;
    };
  };
  connectedRoom: {
    id: string;
    referenceId: string;
    name: string;
    description: string;
  };
};

type PaymentModesQueryVariables = {
  vendorId: string;
};

const GET_PAYMENT_INFORMATION = gql`
  query getPaymentInformation($vendorId: ID!) {
    vendor(id: $vendorId) {
      id
      paymentModes
    }
    purse(vendorId: $vendorId) {
      id
      amount
      allowPartialPayments
      issuedBy {
        name
      }
    }
    connectedRoom(vendorId: $vendorId) {
      id
      referenceId
      name
      description
    }
  }
`;

const PaymentOption: FC<{
  title: string;
  description?: string;
  payableAmount: string;
  paymentMode: string;
  selected: boolean;
  onSelect: () => void;
}> = ({ title, description, payableAmount, paymentMode, selected = false, onSelect }) => (
  <div
    className={classNames(theme.paymentOption, selected ? theme.selected : null)}
    onClick={onSelect}
  >
    <Radio
      checked={selected}
      onChange={onSelect}
      value={paymentMode}
      name={paymentMode}
      size="small"
    />
    <div className={theme.content}>
      <p className={theme.header}>{title}</p>
      <p className={theme.description}>{description}</p>
      <div className={theme.payableAmountContainer}>
        <span>{payableAmount}</span>
      </div>
    </div>
  </div>
);

const PurseModal: FC<{
  open: boolean;
  onClose: () => void;
  goToPayment: (paymentMode: 'PAYMENT_GATEWAY' | 'PURSE' | 'BILL_TO_ROOM' | 'BILL_TO_SPOT') => void;
  totalAmount: number;
  vendorId: string;
  spotId: string | null;
}> = ({ open, onClose, goToPayment, totalAmount, vendorId, spotId }) => {
  const [paymentOption, setPaymentOption] = useState<
    'PAYMENT_GATEWAY' | 'PURSE' | 'BILL_TO_ROOM' | 'BILL_TO_SPOT'
  >('PAYMENT_GATEWAY');

  const { data, loading, error, refetch } = useQuery<
    PaymentModesQueryResponse,
    PaymentModesQueryVariables
  >(GET_PAYMENT_INFORMATION, {
    fetchPolicy: 'network-only',
    variables: {
      vendorId,
    },
  });

  const renderContent = () => {
    if (loading) {
      return <LoadingIndicator />;
    }

    if (error || !data) {
      return <ErrorMessage refresh={refetch} />;
    }

    const paymentModes = data.vendor.paymentModes;
    const purse = data.purse;
    const connectedRoom = data.connectedRoom;

    const getFinalAmount = () => {
      if (paymentOption === 'PAYMENT_GATEWAY') return totalAmount;
      if (purse.amount >= totalAmount) return 0;
      return totalAmount - purse.amount;
    };

    function showPurseOption() {
      if (!paymentModes.includes('PURSE') || !purse || !purse.amount) return null;

      if (purse.amount < totalAmount && !purse.allowPartialPayments) return null;

      return (
        <PaymentOption
          title="Use Credits"
          description={`You have ₹ ${purse.amount} credits issued by ${purse.issuedBy.name}.`}
          payableAmount={`You Pay: ₹ ${
            purse.amount >= totalAmount ? 0 : totalAmount - purse.amount
          }`}
          paymentMode="PURSE"
          selected={paymentOption === 'PURSE'}
          onSelect={() => setPaymentOption('PURSE')}
        />
      );
    }

    function showBillToRoomOption() {
      if (!paymentModes.includes('BILL_TO_ROOM') || !connectedRoom) return null;

      return (
        <PaymentOption
          title="Bill to room"
          description={`You can add this to your bill for ${connectedRoom.name} at ${connectedRoom.description}.`}
          payableAmount={`You Pay: ₹ ${totalAmount} at Checkout`}
          paymentMode="BILL_TO_ROOM"
          selected={paymentOption === 'BILL_TO_ROOM'}
          onSelect={() => setPaymentOption('BILL_TO_ROOM')}
        />
      );
    }

    function showBillToSpotOption() {
      if (!paymentModes.includes('BILL_TO_SPOT') || !spotId) return null;

      return (
        <PaymentOption
          title="Pay Offline"
          description={`You can order now and pay at the premises once you're done.`}
          payableAmount={`You Pay: ₹ ${totalAmount} in person`}
          paymentMode="BILL_TO_SPOT"
          selected={paymentOption === 'BILL_TO_SPOT'}
          onSelect={() => setPaymentOption('BILL_TO_SPOT')}
        />
      );
    }

    function showPaymentGatewayOption() {
      if (!paymentModes.includes('PAYMENT_GATEWAY')) return null;

      return (
        <PaymentOption
          title={paymentModes.includes('PURSE') ? "Don't use credits" : 'Pay Now'}
          payableAmount={`You Pay: ₹ ${totalAmount}`}
          paymentMode="PAYMENT_GATEWAY"
          selected={paymentOption === 'PAYMENT_GATEWAY'}
          onSelect={() => setPaymentOption('PAYMENT_GATEWAY')}
        />
      );
    }

    return (
      <>
        <DialogContent>
          <div className={theme.purseModalContainer}>
            <div className={theme.totalAmountContainer}>
              <span>Total Amount</span>
              <span>₹ {totalAmount}</span>
            </div>
            {showPurseOption()}
            {showBillToRoomOption()}
            {showBillToSpotOption()}
            {showPaymentGatewayOption()}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={() => {
              goToPayment(paymentOption);
              onClose();
            }}
            variant="contained"
          >
            {paymentOption === 'PAYMENT_GATEWAY' || paymentOption === 'PURSE'
              ? `Pay (₹ ${getFinalAmount()})`
              : 'PAY LATER'}
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      {renderContent()}
    </Dialog>
  );
};

export default PurseModal;
