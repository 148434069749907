import { FC, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import QRCode from 'react-qr-code';
import { Button, TextField } from '@mui/material';
import { useQuery, useMutation, gql } from '@apollo/client';
import moment from 'moment';
import StarRatings from 'react-star-ratings';

import { getStatusInformation } from 'constants/index';

import DefaultLayout from 'layouts/DefaultLayout';

import FoodIcon from 'components/FoodIcon';
import Divider from 'components/Divider';
import LoadingIndicator from 'components/LoadingIndicator';
import Title from 'components/Title';
import ErrorMessage from 'components/ErrorMessage';

import theme from './theme.module.scss';

export interface DishType {
  id: string;
  referenceId: string;
  name: string;
  price: number;
  isVegetarian: boolean;
  deliveryScheduledAt: string;
  quantity: number;
  options: {
    id: string;
    name: string;
  }[];
  combinations: {
    option: {
      id: string;
      name: string;
      price: number;
    };
    addOns: {
      id: string;
      name: string;
      price: number;
    }[];
  }[];
  addOns: {
    id: string;
    name: string;
  }[];
  category: {
    id: string;
    name: string;
  };
}

const ORDER = gql`
  query getOrder($id: ID!) {
    order(id: $id) {
      id
      referenceId
      status
      totalPrice
      createdAt
      notes
      feedback {
        rating
        comments
      }
      deliveryScheduledAt
      vendor {
        name
      }
      dishes {
        id
        name
        price
        isVegetarian
        quantity
        category {
          id
          name
        }
        combinations {
          option {
            id
            name
            price
          }
          addOns {
            id
            name
            price
          }
        }
      }
    }
  }
`;

const DishItem: FC<{
  name: string;
  price: number;
  quantity?: number;
  isVegetarian?: boolean;
}> = ({ name, price, quantity, isVegetarian }) => (
  <div className={theme.dishCard}>
    {isVegetarian !== undefined ? <FoodIcon isVeg={isVegetarian} /> : null}
    <div className={theme.informationContainer}>
      <div className={theme.left}>
        <p className={theme.name}>
          {name} {quantity ? `x ${quantity}` : null}
        </p>
      </div>
      <div className={theme.right}>
        <p className={theme.price}>₹ {quantity ? price * quantity : price}</p>
      </div>
    </div>
  </div>
);

const OrderSummary: FC<{
  dishes: DishType[];
  notes: string;
  vendorName: string;
  totalPrice: number;
  createdAt: string;
  deliveryScheduledAt: string;
}> = ({ dishes, notes, vendorName, totalPrice, createdAt, deliveryScheduledAt }) => {
  const dishesGroupedByCategory: {
    [category: string]: DishType[];
  } = dishes.reduce(function (acc, curr) {
    const category = curr.category.name;
    if (!acc.hasOwnProperty(category)) {
      acc[category] = [];
    }
    acc[category].push(curr);
    return acc;
  }, {});

  const categories = Object.keys(dishesGroupedByCategory);
  const orderTime = moment(createdAt).format('D MMMM YYYY, h:mm A');
  const scheduledTime = moment(deliveryScheduledAt).format('h:mm A');

  return (
    <div className={theme.summaryContainer}>
      <p className={theme.subHeading}>ORDER SUMMARY</p>
      <p className={theme.vendorName}>{vendorName}</p>
      <p className={theme.orderTime}>{orderTime}</p>
      {categories.map((category) => (
        <div key={category}>
          {dishesGroupedByCategory[category].map((dish) => (
            <div key={dish.id}>
              <DishItem
                name={dish.name}
                price={dish.price}
                quantity={dish.quantity}
                isVegetarian={dish.isVegetarian}
              />
              {dish.combinations &&
                dish.combinations.map((combination, i) => (
                  <div key={i}>
                    {combination.option ? (
                      <DishItem name={combination.option.name} price={combination.option.price} />
                    ) : null}
                    {combination.addOns.map((addOn) => (
                      <DishItem key={addOn.id} name={addOn.name} price={addOn.price} />
                    ))}
                  </div>
                ))}
            </div>
          ))}
        </div>
      ))}
      <Divider />
      <div className={theme.totalContainer}>
        <span className={theme.label}>Total</span>
        <span className={theme.value}>₹ {totalPrice}</span>
      </div>
      {notes ? (
        <>
          <Divider />
          <div className={theme.notesContainer}>
            <p className={theme.subHeading}>NOTES</p>
            <p>{notes}</p>
          </div>
        </>
      ) : null}
      {/* {scheduledTime ? (
        <p className={theme.orderTime}>Delivery Scheduled At {scheduledTime}</p>
      ) : null} */}
    </div>
  );
};

const GIVE_FEEDBACK = gql`
  mutation updateOrderFeedback($orderId: ID!, $rating: Int, $comments: String) {
    updateOrderFeedback(id: $orderId, rating: $rating, comments: $comments) {
      id
      feedback {
        rating
        comments
      }
    }
  }
`;

const Feedback: FC<{
  orderId: string;
  feedback?: { rating: number; comments: string };
}> = ({ orderId, feedback }) => {
  const [updateOrderFeedback] = useMutation(GIVE_FEEDBACK, {
    onCompleted(data) {
      console.log('successfully updated feedback.');
    },
    onError(e) {
      // Alert.alert('Error', 'There was an error processing this request. Please try later.');
    },
  });

  const existingRating = feedback && feedback.rating ? feedback.rating : 0;
  const existingComments = feedback && feedback.comments ? feedback.comments : '';
  const [rating, setRating] = useState<number>(existingRating);
  const [comments, setComments] = useState<string>(existingComments);

  return (
    <div className={theme.feedbackContainer}>
      <div
        className={theme.starContainer}
        style={{ pointerEvents: !!existingRating ? 'none' : 'all' }}
      >
        <StarRatings
          rating={rating}
          starRatedColor="rgb(255,176,71)"
          starHoverColor="rgb(255,176,71)"
          changeRating={(value) => setRating(value)}
          numberOfStars={5}
          name="rating"
          isSelectable={!!existingRating}
        />
      </div>
      {existingRating ? (
        <p className={theme.comments}>{comments ? `"${comments}"` : null}</p>
      ) : (
        <div className={theme.inputContainer}>
          <TextField
            fullWidth
            size="small"
            className={theme.formInput}
            placeholder={'Comments?'}
            onChange={(e) => setComments(e.target.value)}
            value={comments}
          />
        </div>
      )}
      {existingRating === 0 && rating ? (
        <div className={theme.submitButton}>
          <Button
            variant="contained"
            onClick={() =>
              updateOrderFeedback({
                variables: {
                  orderId,
                  rating,
                  comments,
                },
              })
            }
          >
            Submit
          </Button>
        </div>
      ) : null}
    </div>
  );
};

function useURLQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const OrderStatus: FC = () => {
  const history = useHistory();
  const { orderId } = useParams<{ orderId: string }>();
  const queryParams = useURLQueryParams();

  const { loading, error, data, refetch } = useQuery(ORDER, {
    variables: {
      id: orderId,
    },
    pollInterval: 2000,
    fetchPolicy: 'network-only',
  });

  const renderContent = () => {
    if (loading) {
      return <LoadingIndicator />;
    }

    if (error || !data) {
      return <ErrorMessage refresh={refetch} />;
    }

    const order = data.order;

    const vendor = order.vendor;

    const dishes = order.dishes;

    const status = data && data.order && data.order.status ? data.order.status : '';

    const { image, message, label } = getStatusInformation[status.toLowerCase()];

    return (
      <div className={theme.contentContainer}>
        <div className={theme.statusContainer}>
          <p className={theme.header}>{label}</p>
          <p className={theme.referenceId}>Order ID # {order.referenceId}</p>
          <p className={theme.message}>{message}</p>
          {showImage(image, status)}
          {showRating()}
        </div>
        <OrderSummary
          dishes={dishes}
          notes={order.notes}
          vendorName={vendor.name}
          totalPrice={order.totalPrice}
          createdAt={order.createdAt}
          deliveryScheduledAt={order.deliveryScheduledAt}
        />
        {loading ? null : (
          <Button
            variant="outlined"
            size="small"
            className={theme.helpButton}
            onClick={() => history.push('Help')}
          >
            Need Help ?
          </Button>
        )}
      </div>
    );
  };

  function showRating() {
    if (loading || error || !data) return null;

    if (data.order.status !== 'DELIVERED') return null;

    return <Feedback orderId={orderId} feedback={data.order.feedback} />;
  }

  function showImage(image: string, status: string) {
    if (status === 'READY')
      return (
        <div className={theme.imageContainer}>
          <QRCode value={orderId} bgColor="black" fgColor="#f5f5f5" />
        </div>
      );

    if (image)
      return (
        <div className={theme.imageContainer}>
          <img src={image} alt={status} className={theme.image} key={status} />
        </div>
      );

    return null;
  }

  return (
    <DefaultLayout>
      <Title
        name="Order Details"
        navigateBack={() => {
          if (queryParams.get('shouldRedirectToVendor') === 'true') {
            history.push('/home');
          } else {
            history.goBack();
          }
        }}
      />
      {renderContent()}
    </DefaultLayout>
  );
};

export default OrderStatus;
