import { FC } from 'react';
import { Grid } from '@mui/material';
import BottomBar from 'components/BottomBar';

import { isAuthenticated } from 'utils/auth';

import theme from './theme.module.scss';

const DefaultLayout: FC = (props) => {
  return (
    <Grid container alignItems="center" justifyContent="center" direction="column">
      <Grid container xl={3} lg={5} md={8} sm={12} xs={12} direction="column">
        <div className={theme.childrenContainer}>{props.children}</div>
        {isAuthenticated() ? <BottomBar /> : null}
      </Grid>
    </Grid>
  );
};

export default DefaultLayout;
