import createdImage from 'media/sandglass.png';
import paidImage from 'media/paymentConfirmed.png';
import preparingImage from 'media/preparing.png';

export const getStatusInformation = {
  created: {
    image: createdImage,
    message: "We're checking your payment and we'll update this screen as soon as we receive it.",
    label: 'PAYMENT PROCESSING',
  },
  paid: {
    image: paidImage,
    message: 'Your payment was successful! Hang on while the restaurant confirms your order.',
    label: 'PAYMENT CONFIRMED',
  },
  billed: {
    image: paidImage,
    message:
      'Your order was billed to your room! Hang on while the restaurant confirms your order.',
    label: 'ADDED TO ROOM BILL',
  },
  inprogress: {
    image: preparingImage,
    message: 'Awesome! Your food is being prepared and will be ready shortly.',
    label: 'PREPARING',
  },
  ready: {
    image: null,
    message:
      'The order is ready. Kindly visit the counter to pick it up. This QR code will be scanned for verification.',
    label: 'READY',
  },
  delivered: {
    image: null,
    message:
      'Thanks for ordering. Let us know about your experience so we can improve the next time.',
    label: 'PICKED UP',
  },
  cancelled: {
    image: null,
    message:
      'Sorry, your transaction was not completed or your order is cancelled, contact support@illude.co for more details.',
    label: 'CANCELLED',
  },
  disputed: {
    image: null,
    message:
      'Sorry, the dish that you have requested is not available, we will refund your amount to you.',
    label: 'ERROR',
  },
  failed: {
    image: null,
    message:
      'Sorry, your transaction was not completed or your order is cancelled, contact support@illude.co for more details.',
    label: 'ERROR',
  },
};

export const PAYMENT_MODE = {
  PAYPAL: 'Paypal',
  WALLET: 'Wallet',
  CREDIT_CARD: 'Credit Card',
  DEBIT_CARD: 'Debit Card',
  AIRTEL_MONEY: 'Airtle Money',
  NET_BANKING: 'Net Banking',
};
