import { FC, useContext } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import VendorCard from 'components/VendorCard';
import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import LocationContext from 'contexts/LocationContext';
import DefaultLayout from 'layouts/DefaultLayout';

import theme from './theme.module.scss';

type VendorQueryResponse = {
  foodCourt: {
    id: string;
    name: string;
    vendors: {
      id: string;
      name: string;
      description: string;
      shortDescription: string;
      image: string;
      status: string;
      thumbnail: string;
    }[];
  };
};

type VendorQueryVariables = {
  foodCourtId: string;
};

const VENDORS = gql`
  query getData($foodCourtId: ID!) {
    foodCourt(id: $foodCourtId) {
      id
      name
      vendors {
        id
        name
        description
        shortDescription
        image
        thumbnail
        status
      }
    }
  }
`;

const Home: FC = () => {
  const history = useHistory();
  const { currentFoodCourt } = useContext(LocationContext);

  const foodCourtId = currentFoodCourt;
  const foodCourtLocation = 'Food Court';

  const { loading, error, data, refetch } = useQuery<VendorQueryResponse, VendorQueryVariables>(
    VENDORS,
    {
      variables: {
        foodCourtId,
      },
      fetchPolicy: 'network-only',
      pollInterval: 30000,
    }
  );

  const renderLocation = () => {
    if (loading || error || !data) return null;

    const foodCourt = data.foodCourt;

    return (
      <div className={theme.locationContainer} onClick={() => history.push('change-location')}>
        <div>
          <h3 className={theme.name}>{foodCourt.name}</h3>
          <p className={theme.address}>{foodCourtLocation}</p>
        </div>
        <KeyboardArrowDownIcon />
      </div>
    );
  };

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage refresh={refetch} />;

    const foodCourt = data.foodCourt;
    const vendors = foodCourt.vendors;

    if (vendors.length === 0) {
      return (
        <div className={theme.emptyVendors}>
          <p className={theme.emptyText}>
            There are no open restaurants at this time. Kindly check back later.
          </p>
        </div>
      );
    }

    return (
      <div className={theme.vendorContainer}>
        {vendors.map((vendor) => (
          <VendorCard
            vendor={vendor}
            key={vendor.id}
            onSelect={() => history.push(`vendor/${vendor.id}`)}
          />
        ))}
      </div>
    );
  };

  return (
    <DefaultLayout>
      {renderLocation()}
      <div className={theme.heading}>
        <h3>Restaurants near you</h3>
        <p className={theme.information}>
          Order food directly from your favourite eateries for pickup.
        </p>
      </div>
      {renderContent()}
    </DefaultLayout>
  );
};

export default Home;
