import { FC } from 'react';
import { useHistory } from 'react-router';
import { useQuery, gql } from '@apollo/client';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import ListButton from 'components/ListButton';

import DefaultLayout from 'layouts/DefaultLayout';

import { logout } from 'utils/auth';

import theme from './theme.module.scss';

type UserQueryResponse = {
  me: {
    id: string;
    firstName: string;
    phoneNumber: string;
  };
};

const USER = gql`
  query user {
    me {
      id
      firstName
      phoneNumber
    }
  }
`;

const ProfileScreen: FC = () => {
  const history = useHistory();

  const { data, loading, error } = useQuery<UserQueryResponse>(USER, {
    fetchPolicy: 'network-only',
  });

  const renderContent = () => {
    if (loading) {
      return <LoadingIndicator />;
    }

    if (error || !data) {
      return <ErrorMessage refresh={() => window.location.reload()} />;
    }

    const user = data.me;

    return (
      <>
        <div className={theme.userDetailsContainer}>
          <div className={theme.avatar} />
          <div className={theme.details}>
            <p className={theme.name}>{user.firstName}</p>
            <p className={theme.phoneNumber}>{user.phoneNumber}</p>
          </div>
        </div>
        <p className={theme.subHeading}>ACCOUNT SETTINGS</p>
        <ListButton
          label="Personal Information"
          icon={<PersonOutlineIcon />}
          onClick={() => history.push('/personal-information')}
        />
        <ListButton
          label="Linked Accounts"
          icon={<BusinessOutlinedIcon />}
          onClick={() => history.push('/linked-accounts')}
        />
        <p className={theme.subHeading}>SUPPORT</p>
        <ListButton
          label="Help"
          icon={<SupportOutlinedIcon />}
          onClick={() => history.push('/help')}
        />
        <ListButton
          label="Terms of Service"
          icon={<DescriptionOutlinedIcon />}
          onClick={() => history.push('/terms-gallery')}
        />
        <ListButton
          label="Log out"
          onClick={() => {
            logout();
            history.push('/home');
          }}
        />
      </>
    );
  };

  return <DefaultLayout>{renderContent()}</DefaultLayout>;
};

export default ProfileScreen;
