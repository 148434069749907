import { FC } from 'react';
import { useHistory } from 'react-router';

import Title from 'components/Title';
import DefaultLayout from 'layouts/DefaultLayout';
import ListButton from 'components/ListButton';

const TermsGallery: FC = () => {
  const history = useHistory();
  return (
    <DefaultLayout>
      <Title name="Terms of Service" navigateBack={() => history.goBack()} />
      <div>
        <p>By using this app, you have agreed to the following policies.</p>
        <ListButton
          label="Usage"
          onClick={() => {
            //@ts-ignore
            window.open('https://cuttle.cc/terms', '_blank').focus();
          }}
        />
        <ListButton
          label="Privacy Policy"
          onClick={() => {
            //@ts-ignore
            window.open('https://cuttle.cc/privacypolicy', '_blank').focus();
          }}
        />
        <ListButton
          label="Cancellation Policy"
          onClick={() => {
            //@ts-ignore
            window.open('https://cuttle.cc/cancellationpolicy', '_blank').focus();
          }}
        />
      </div>
    </DefaultLayout>
  );
};

export default TermsGallery;
