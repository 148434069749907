import { FC } from 'react';
import { useHistory } from 'react-router';
import { useQuery, gql } from '@apollo/client';
import moment from 'moment';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RefreshIcon from '@mui/icons-material/Refresh';

import ErrorMessage from 'components/ErrorMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import Divider from 'components/Divider';

import DefaultLayout from 'layouts/DefaultLayout';

import theme from './theme.module.scss';
import { IconButton } from '@mui/material';

type Order = {
  id: string;
  referenceId: string;
  status: string;
  createdAt: string;
  totalPrice: number;
};

type OrderListQueryResponse = {
  ordersByUser: Order[];
};

const ORDER_LIST = gql`
  query ordersByUser($userId: ID) {
    ordersByUser(userId: $userId) {
      id
      referenceId
      status
      createdAt
      totalPrice
    }
  }
`;

const OrderItem: FC<{ order: Order; viewDetails: (orderId: string) => void }> = ({
  order,
  viewDetails,
}) => {
  const totalPrice = order.totalPrice;
  const createdAt = order.createdAt;
  const orderDate = moment(createdAt).format('D MMMM YYYY, h:mm A');
  const status = order.status;

  const goToDetailsPage = () => {
    viewDetails(order.id);
  };

  return (
    <div className={theme.orderContainer} onClick={goToDetailsPage}>
      <div className={theme.information}>
        <div className={theme.left}>
          <p className={theme.referenceId}>{order.referenceId}</p>
          <p className={theme.price}>₹ {totalPrice}</p>
          <p className={theme.status}>Order Status: {status}</p>
          <p className={theme.orderDate}>{orderDate}</p>
        </div>
        <div className={theme.right}>
          <ChevronRightIcon />
        </div>
      </div>
      <Divider />
    </div>
  );
};

const OrderList: FC<{
  orders: Order[];
  type: string;
  viewDetails: (orderId: string) => void;
  refresh: () => void;
}> = ({ orders, type, viewDetails, refresh }) => {
  if (orders.length) {
    return (
      <div>
        <div className={theme.subHeadingContainer}>
          <span className={theme.subHeading}>{type}</span>
          <IconButton color="primary" component="span" onClick={refresh}>
            <RefreshIcon />
          </IconButton>
        </div>
        {orders.map((order) => (
          <OrderItem key={order.id} order={order} viewDetails={viewDetails} />
        ))}
      </div>
    );
  }
  return null;
};

const OrderListScreen: FC = () => {
  const history = useHistory();

  const { data, loading, error } = useQuery<OrderListQueryResponse>(ORDER_LIST, {
    fetchPolicy: 'network-only',
  });

  const viewDetails = (orderId) => {
    history.push(`/orders/${orderId}`);
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingIndicator />;
    }

    if (error || !data) {
      return <ErrorMessage refresh={() => window.location.reload()} />;
    }

    const orders = data.ordersByUser;

    if (orders.length === 0) {
      return <p>No Orders Found</p>;
    }

    const todayOrders = orders.filter((order) => moment(order.createdAt).isSame(new Date(), 'day'));

    const pastOrders = orders.filter((order) => !moment(order.createdAt).isSame(new Date(), 'day'));

    return (
      <>
        <OrderList
          orders={todayOrders}
          type="TODAY'S ORDERS"
          viewDetails={viewDetails}
          refresh={() => window.location.reload()}
        />
        <OrderList
          orders={pastOrders}
          type="PAST ORDERS"
          viewDetails={viewDetails}
          refresh={() => window.location.reload()}
        />
      </>
    );
  };

  return <DefaultLayout>{renderContent()}</DefaultLayout>;
};

export default OrderListScreen;
