import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import DefaultLayout from 'layouts/DefaultLayout';

import banner from 'media/cuttle-background.jpg';

import theme from './theme.module.scss';

const Landing: FC = () => {
  const history = useHistory();

  return (
    <DefaultLayout>
      <div className={theme.banner} style={{ backgroundImage: `url("${banner}")` }} />
      <div className={theme.container}>
        <div className={theme.phoneNumberContainer}>
          <Button
            variant="contained"
            size="large"
            endIcon={<ChevronRightIcon />}
            onClick={() => history.push('/change-location')}
          >
            Find a food court near you
          </Button>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Landing;
