import { FC } from 'react';
import { Divider, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import theme from './theme.module.scss';

const Title: FC<{
  name?: string;
  navigateBack: () => void;
  showDivider?: boolean;
}> = ({ name, navigateBack, showDivider = true }) => {
  return (
    <div className={theme.title}>
      <div className={theme.titleContainer}>
        {navigateBack ? (
          <IconButton className={theme.navigateBack} onClick={() => navigateBack()}>
            <ArrowBackIcon />
          </IconButton>
        ) : null}
        <span className={theme.name}>{name}</span>
      </div>
      {showDivider ? <Divider /> : null}
    </div>
  );
};

export default Title;
